<template>
    <div class="error404">
        <img style=" cursor: pointer" src="/imgs/404/404.png" @click="reback">
    </div>
</template>
<script>
export default {
   data() {
      return {
      }
   },
//生命周期 - 创建完成（访问当前this实例）
   created(){
   },
   computed:{
   },
//生命周期 - 挂载完成（访问DOM元素）
   methods:{
     reback(){
       this.$router.push("/layout");
     }
   },
}
</script>
<style lang="scss" scoped>
.error404{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
</style>
